<template>
	<div v-if="info">
		<div class="swiper one-swiper" style="height: 538px">
			<el-carousel trigger="click" @change="oneSwiperChange" :interval="5000" height="538px" arrow="always">
				<el-carousel-item v-for="item in info.carousel" :key="item.id">
					<img @click="linkClick(item.url)" :src="item.image"/>
				</el-carousel-item>
				<div class="indicator flex-center" v-if="info.carousel.length != 1">
          <span
						v-for="(item, index) in info.carousel"
						:key="item.id"
						:style="index == oneSwiperIndex ? 'background:#e50114' : ''"
					></span>
				</div>
			</el-carousel>
		</div>
		<!-- 新闻资讯 -->
		<div class="news content flex-space-between">
			<div class="left flex-direction-column">
				<div class="title flex-space-between">
					<span>新闻资讯</span>
					<span @click="moreClick(7)">更多+</span>
				</div>
				<div class="news-list flex-space-between" v-if="info.news.length != 0">
					<div class="news-list-left">
						<el-carousel
							class="two-swiper"
							indicator-position="none"
							trigger="click"
							:interval="5000"
							height="308px"
							arrow="always"
						>
							<el-carousel-item v-for="item in info.news_image" :key="item.id">
								<img @click="newDetail(item)" :src="item.image"/>
								<div class="news-list-left-desc one-line" @click="newDetail(item)">{{ item.title }}</div>
							</el-carousel-item>
						</el-carousel>
					</div>

					<div class="news-list-right list flex-direction-column" style="margin-top: 0">
						<div class="list_box flex-direction-column">
							<div
								style="padding: 0 5px"
								class="list_item flex-space-between"
								v-for="item in info.news"
								:key="item.id"
								@click="newDetail(item)"
							>
								<div class="list_item_left flex">
									<span></span>
									<p style="width: 220px" class="one-line">{{ item.title }}</p>
								</div>
								<div class="list_item_right">{{ item.date }}</div>
							</div>
						</div>
					</div>
				</div>
				<Lack
					v-if="info.news.length == 0"
					text="暂无内容"
					minHeight="370px"
					imgWidth="102px"
					imgHeight="68px"
					:imgSrc="require('../../assets/image/img/15.png')"
				></Lack>
			</div>
			<div class="right">
				<div class="title flex-space-between">
					<span>服务中心</span>
				</div>
				<div class="new-list-right flex-wrap">
					<div
						class="new-list-right-item flex-direction-column"
						@click="serviceClick(item)"
						v-for="(item, index) in info.serve"
						:key="index"
					>
						<img :src="require(`@/assets/image/icon/0${index + 1}.png`)"/>
						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 通知公告 -->
		<div class="notice content flex-space-between">
			<div class="left flex-direction-column" :style="getNoHeight(info.notice.length, info.image.length)">
				<div class="title flex-space-between">
					<span>通知公告</span>
					<span @click="moreClick(5)">更多+</span>
				</div>
				<div class="list flex-direction-column">
					<div class="list_box flex-direction-column" v-if="info.notice.length > 0">
						<div
							class="list_item flex-space-between"
							@click="listClick(item, 1)"
							v-for="item in info.notice.slice(0, 5)"
							:key="item.id"
						>
							<div class="list_item_left flex">
								<span></span>
								<p class="name one-line">
									{{ item.title }}
								</p>
							</div>
							<div class="list_item_right">{{ item.date }}</div>
						</div>
					</div>
					<div class="line" v-if="info.notice.length > 5"></div>
					<div class="list_box flex-direction-column" v-if="info.notice.length > 5">
						<div
							class="list_item flex-space-between"
							@click="listClick(item, 1)"
							v-for="item in info.notice.slice(5, 10)"
							:key="item.id"
						>
							<div class="list_item_left flex">
								<span></span>
								<p class="name one-line">{{ item.title }}</p>
							</div>
							<div class="list_item_right">{{ item.date }}</div>
						</div>
					</div>
					<Lack
						v-if="info.notice.length == 0"
						text="暂无内容"
						minHeight="410px"
						imgWidth="102px"
						imgHeight="68"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
			<div class="right flex-direction-column" :style="getNoHeight(info.notice.length, info.image.length)">
				<div class="title flex-space-between">
					<span>图片锦集</span>
					<span @click="moreClick(1)">更多+</span>
				</div>
				<div class="notice-right">
					<div
						class="notice-right-item flex-space-between"
						@click="itemClick(item, 3)"
						v-for="item in info.image"
						:key="item.id"
					>
						<img :src="item.image"/>
						<div class="notice-right-item-right flex-direction-column">
							<span class="two-line">{{ item.name }}</span>
							<p>{{ item.date }}</p>
						</div>
					</div>
					<Lack
						v-if="info.image.length == 0"
						text="暂无内容"
						minHeight="423px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
		</div>
		<div v-if="info.advert.length != 0" class="swiper two-swiper" style="height: 140px; width: 1360px; margin: 0 auto">
			<el-carousel trigger="click" :interval="5000" height="140px">
				<el-carousel-item v-for="item in info.advert" :key="item.id">
					<img @click="linkClick(item.url)" :src="item.image"/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 会员之家 -->
		<div class="policy content flex-space-between">
			<div class="left flex-direction-column" :style="getPoliHeight(info.policy.length, info.video.slice(0, 4).length)">
				<div class="title flex-space-between">
					<span>会员之家</span>
					<span @click="moreClick(6)">更多+</span>
				</div>
				<div class="list flex-direction-column">
					<div class="list_box flex-direction-column" v-if="info.policy.length > 0">
						<div
							class="list_item flex-space-between"
							@click="listClick(item, 2)"
							v-for="item in info.policy.slice(0, 5)"
							:key="item.id"
						>
							<div class="list_item_left flex">
								<span></span>
								<p class="name one-line">{{ item.title }}</p>
							</div>
							<div class="list_item_right">{{ item.date }}</div>
						</div>
					</div>
					<div class="line" v-if="info.policy.length > 5"></div>
					<div class="list_box flex-direction-column" v-if="info.policy.length > 5">
						<div
							class="list_item flex-space-between"
							@click="listClick(item, 2)"
							v-for="item in info.policy.slice(5, 8)"
							:key="item.id"
						>
							<div class="list_item_left flex">
								<span></span>
								<p>{{ item.title }}</p>
							</div>
							<div class="list_item_right">{{ item.date }}</div>
						</div>
					</div>
					<Lack
						v-if="info.policy.length == 0"
						text="暂无内容"
						minHeight="334px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
			<div
				class="right flex-direction-column"
				:style="getPoliHeight(info.policy.length, info.video.slice(0, 4).length)"
			>
				<div class="title flex-space-between">
					<span>视频锦集</span>
					<span @click="moreClick(2)">更多+</span>
				</div>
				<div class="policy-right flex-wrap">
					<div
						class="policy-right-item flex-direction-column"
						@click="itemClick(item, 2)"
						v-for="item in info.video.slice(0, 4)"
						:key="item.id"
					>
						<img :src="item.image"/>
						<div class="mask flex-center">
							<img src="@/assets/image/icon/11.png"/>
						</div>
						<span class="one-line">{{ item.name }}</span>
						<p>{{ item.date }}</p>
					</div>
					<Lack
						v-if="info.video.length == 0"
						text="暂无内容"
						minHeight="340px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
		</div>
		<!-- 运动员 -->
		<div class="athletes content flex-space-between">
			<div class="athletes-box flex-direction-column">
				<div class="title flex-space-between">
					<span>金牌教练员</span>
					<span @click="moreClick(3)">更多+</span>
				</div>
				<!-- <div class="athletes-per flex-wrap" :style="getPerHeight(info.coach.length, info.athletes.length)">
					<div class="athletes-per-item" v-for="item in info.coach" :key="item.id">
						<img :src="item.image" />
						<span>{{ item.name }}</span>
						<p class="two-line">{{ item.memo }}</p>
					</div>
				</div> -->
				<div class="swiper three-swiper" style="height: 405px;padding-bottom: 15px;padding-top: 15px"
						 v-if="info.coach.length != 0">
					<el-carousel trigger="click" @change="oneSwiperChange" :interval="5000" height="405px" arrow="always">
						<el-carousel-item v-for="(i, v) in info.coach" :key="v">
							<div class="indicator flex-center">
								<div class="athletes-per">
									<div class="athletes-per-item" v-for="ii in i" :key="ii.id">
										<img :src="ii.image"/>
										<span>{{ ii.name }}</span>
										<div class="three-line2">{{ ii.memo }}</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<Lack
					v-if="info.coach.length == 0"
					text="暂无内容"
					minHeight="620px"
					imgWidth="102px"
					imgHeight="68"
					:imgSrc="require('../../assets/image/img/15.png')"
				></Lack>
			</div>
			<div class="athletes-box flex-direction-column">
				<div class="title flex-space-between">
					<span>金牌运动员</span>
					<span @click="moreClick(4)">更多+</span>
				</div>
				<!-- <div class="athletes-per flex-wrap" :style="getPerHeight(info.athletes.length, info.athletes.length)">
					<div class="athletes-per-item" v-for="item in info.athletes" :key="item.id">
						<img :src="item.image" />
						<span>{{ item.name }}</span>
						<p>{{ item.memo }}</p>
					</div>
				</div> -->
				<div class="swiper three-swiper" style="height: 405px;padding-bottom: 15px;padding-top: 15px"
						 v-if="info.athletes.length != 0">
					<el-carousel trigger="click" @change="oneSwiperChange" :interval="5000" height="405px" arrow="always">
						<el-carousel-item v-for="(i, v) in info.athletes" :key="v">
							<div class="indicator flex-center">
								<div class="athletes-per">
									<div class="athletes-per-item" v-for="ii in i" :key="ii.id">
										<img :src="ii.image"/>
										<span>{{ ii.name }}</span>
										<div class="three-line2">{{ ii.memo }}</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<Lack
					v-if="info.athletes.length == 0"
					minHeight="620px"
					text="暂无内容"
					imgWidth="102px"
					imgHeight="68"
					:imgSrc="require('../../assets/image/img/15.png')"
				></Lack>
			</div>
		</div>
		<!-- 赞助商及合作伙伴 -->
		<div class="sponsor content flex-direction-column" v-if="info.sponsor.length != 0">
			<div class="sponsor-title-one">赞助商及合作伙伴</div>
			<div class="flex-direction-column" v-for="(i, v) in info.sponsor" :key="v">
				<div class="sponsor-title-two" v-if="i.data.length">{{ i.name }}</div>
				<div class="sponsor-official flex-wrap" style="justify-content: center">
					<div class="sponsor-item flex-center" v-for="ii in i.data" :key="ii.id" @click="linkClick(ii.url)">
						<img :src="ii.image"/>
					</div>
				</div>
			</div>
		</div>
		<!-- 友情链接 -->
		<div v-if="info.friendship.length != 0" class="sponsor content flex" style="padding: 55px 0px 44px 0px">
			<div class="left">友情链接：</div>
			<div class="sponsor-official link-wrap flex-wrap">
				<div
					class="link flex-direction-column"
					:style="info.friendship.length == 1 ? 'margin-right: 0;' : ''"
					v-for="item in info.friendship"
					:key="item.id"
					@click="linkClick(item.url)"
				>
					<img :src="item.logo"/>
					<span>{{ item.name }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {postHome} from '@/service/api/index'
import Lack from '../../components/lack/lack.vue'

export default {
	components: {Lack},
	data() {
		return {
			info: '',
			newsIndex: 0,
			oneSwiperIndex: 0,
			serviceList: []
		}
	},
	mounted() {
		this.postHome()
	},

	methods: {
		newDetail(item) {
			if (item.type_two == 1) return window.open(item.url)
			var obj = {
				id: item.child_id,
				type: 0,
				name: '新闻资讯'
			}
			this.$router.push({
				name: 'pudetail',
				params: {
					info: item,
					home: obj
				}
			})
		},
		postHome() {
			postHome().then((res) => {
				this.info = Object.freeze(res.msg)
				// console.log(this.info)
			})
		},
		oneSwiperChange(index) {
			this.oneSwiperIndex = index
		},
		newsClick(index) {
			this.newsIndex = index
		},
		serviceClick(item) {
			if (item.type > 2) {
				window.open(item.url, '_blank')
			} else {
				var name = ''
				if (item.type == 1) {
					name = 'information'
				} else if (item.type == 2) {
					name = 'opinion'
				}

				this.$router.push({
					name: name,
					params: {
						info: item
					}
				})
			}
		},
		moreClick(status) {
			// 图片锦集
			if (status == 1) {
				this.$router.push({
					path: '/pwrc',
					query: {
						pathArr: ['首页', '图片锦集']
					}
				})
			}
			// 视频锦集
			else if (status == 2) {
				this.$router.push({
					path: '/vwrc',
					query: {
						pathArr: ['首页', '视频锦集']
					}
				})
			}
			// 金牌教练员
			else if (status == 3) {
				this.$router.push({
					name: 'coachANDathletes',
					params: {
						info: {
							status: 0,
							id: 1,
							pathArr: ['首页', '金牌教练员']
						}
					}
				})
			}
			// 金牌运动员
			else if (status == 4) {
				this.$router.push({
					name: 'coachANDathletes',
					params: {
						info: {
							status: 1,
							id: 2,
							pathArr: ['首页', '金牌运动员']
						}
					}
				})
			} else if (status == 5) {
				this.$router.push({
					name: 'plist',
					params: {
						info: {
							id: this.info.notice_id,
							name: '通知公告',
							type: 0,
							home: true
						}
					}
				})
			} else if (status == 6) {
				this.$router.push({
					name: 'plist',
					params: {
						info: {
							id: this.info.policy_id,
							name: '政策规定',
							type: 0,
							home: true
						}
					}
				})
			} else if (status == 7) {
				this.$router.push({
					name: 'plist',
					params: {
						info: {
							id: this.info.news_id,
							name: '新闻资讯',
							type: 0,
							home: true
						}
					}
				})
			}
		},
		itemClick(item, status) {
			if (status == 1) {
				this.$router.push('/pdetail')
			} else if (status == 2) {
				this.$router.push({
					name: 'vdetail',
					params: {
						info: item,
						pathArr: ['首页', '视频锦集']
					}
				})
			} else {
				this.$router.push({
					name: 'pdetail',
					params: {
						info: item,
						pathArr: ['首页', '图片锦集']
					}
				})
			}
		},
		linkClick(url) {
			if (url) {
				window.open(url, '_blank')
			}
		},
		listClick(item, status) {
			if (item.type_two == 1) return window.open(item.url)
			var obj = {}
			if (status == 1) {
				obj = {
					id: item.child_id,
					type: 0,
					name: '通知公告'
				}
			} else {
				obj = {
					id: item.child_id,
					type: 0,
					name: '政策规定'
				}
			}
			this.$router.push({
				name: 'pudetail',
				params: {
					info: item,
					home: obj
				}
			})
		},
		getPerHeight(len1, len2) {
			if (Math.ceil(len1 / 4) == 1 && Math.ceil(len2 / 2) == 1) {
				return 'min-height:250px'
			} else {
				return 'min-height:520px'
			}
		},
		getPoliHeight(len1, len2) {
			if (Math.ceil(len1 / 4) == 1 && Math.ceil(len2 / 2) == 1) {
				return 'min-height:237px'
			} else {
				return 'min-height:398.5px'
			}
		},
		getNoHeight(len1, len2) {
			if (Math.ceil(len1 / 2) == 1 && Math.ceil(len2 / 2) == 1) {
				return 'min-height:237px'
			} else {
				if (len1 == 6) {
					return 'min-height: 325px'
				} else if (len1 == 7) {
					return 'min-height: 355px'
				} else {
					return 'min-height: 475px'
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.name {
	width: 750px;
}

.three-swiper {
	/deep/ .el-carousel__arrow--left {
		display: none !important;
	}

	/deep/ .el-carousel__arrow--right {
		display: none !important;
	}
}

.one-swiper {
	/deep/ .el-carousel__arrow--left {
		width: 70px;
		height: 70px;
		left: 200px;
		font-size: 25px;
	}

	/deep/ .el-carousel__arrow--right {
		width: 70px;
		height: 70px;
		right: 200px;
		font-size: 25px;
	}

	/deep/ .el-carousel__button {
		display: none;
	}
}

.two-swiper {
	/deep/ .el-carousel__arrow--left {
		display: none !important;
	}

	/deep/ .el-carousel__arrow--right {
		display: none !important;
	}

	/deep/ .el-carousel__button {
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}
}

.content {
	.left {
		width: 940px;
	}

	.right {
		width: 400px;
	}
}

.swiper {
	width: 100%;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.indicator {
		width: 100%;
		position: absolute;
		bottom: 40px;
		z-index: 10;

		span {
			width: 37px;
			height: 6px;
			background: rgba(255, 255, 255);
			border-radius: 3px;
			margin-right: 10px;
		}
	}
}

.news {
	margin: 20px auto 20px auto;

	.left {
		background: #fff;
		width: 895px;
		padding: 20px 22px 34px 20px;
		border-radius: 3px;
	}

	.news-list {
		width: 100%;
		height: 315px;
		margin-top: 18px;
		align-items: flex-start;

		.news-list-left {
			width: 513px;
			height: 308px;
			// height: 356px;
			position: relative;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 3px;
			}

			.news-list-left-desc {
				width: 470px;
				padding: 14px 21px;
				background: rgba(0, 0, 0, 0.8);
				border-radius: 0px 0px 3px 3px;
				color: #fff;
				font-size: 16px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

		.news-list-right {
			width: 360px !important;
			height: 100%;

			.news-item {
				// width: 360px;
				margin-left: 21px;
				margin-bottom: 10px;
				background-image: url('../../assets/image/img/09.png');
				background-repeat: no-repeat;
				// background-size: 100% 60px;
				background-size: 360px 68px;
				background-position-x: 10px;
				cursor: pointer;

				&:nth-child(5n) {
					margin-bottom: 0;
				}

				.news-item-left {
					width: 70px;
					height: 68px;
					border: 1px solid #eeeeee;
					border-radius: 3px;
					text-align: center;
					align-items: center;
					justify-content: center;
					position: relative;

					span {
						&:nth-child(1) {
							font-size: 22px;
							font-weight: bold;
						}

						&:nth-child(2) {
							font-size: 12px;
							color: #777777;
							line-height: 20px;
						}
					}

					p {
						position: absolute;
						height: 16px;
						background: #e50114;
						width: 1px;
						right: 0px;
					}
				}

				.news-item-right {
					padding: 5px 0;
					width: 241px;
					margin-left: 18px;
					font-size: 14px;
					line-height: 25px;
					max-height: 50px;
				}
			}
		}
	}

	.right {
		background: #fff;
		padding: 20px 22px 10px 20px;
		border-radius: 3px;
		margin-left: 20px;
		width: 358px;

		.new-list-right {
			height: 356px;
		}

		.new-list-right {
			.new-list-right-item {
				width: 50%;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					transform: translateY(-10px);
				}

				img {
					width: 56px;
					height: 56px;
					object-fit: cover;
				}

				span {
					width: 100%;
					height: 37px;
					line-height: 37px;
					font-size: 20px;
					text-align: center;
					margin-top: 26px;
				}
			}
		}
	}
}

.notice {
	margin: 20px auto 20px auto;
	align-items: flex-start;

	.left {
		width: 940px;
		background: #fff;
		padding: 20px 0px 23px 0px;
		border-radius: 3px;
		// min-height: 475px;
		.title {
			width: 897px;
			margin: 0 22px;
		}
	}

	.right {
		background: #fff;
		width: 358px;
		border-radius: 3px;
		margin-left: 20px;
		margin-bottom: 4px;
		// min-height: 475px;
		padding: 20px 22px 22px 20px;

		.notice-right {
			width: 100%;

			.notice-right-item {
				margin-top: 16px;
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					transform: translateY(-5px);
				}

				img {
					width: 144px;
					height: 90px;
					border-radius: 3px;
					object-fit: cover;
				}

				.notice-right-item-right {
					flex: 1;
					height: 90px;
					margin-left: 13px;
					justify-content: space-between;

					&:hover {
						color: #e50114;

						p {
							color: #e50114;
						}
					}

					span {
						height: 47px;
						font-size: 16px;
						line-height: 25px;
					}

					p {
						height: 22px;
						font-size: 14px;
						line-height: 25px;
						color: #999999;
					}
				}
			}
		}
	}
}

.policy {
	margin: 20px auto 20px auto;
	align-items: flex-start;

	.left {
		width: 940px;
		background: #fff;
		padding: 20px 0px 23.5px 0px;
		border-radius: 3px;
		// min-height: 398.5px;
		.title {
			width: 897px;
			margin: 0 22px;
		}
	}

	.right {
		background: #fff;
		width: 358px;
		padding: 20px 22px 13px 20px;
		border-radius: 3px;
		margin-left: 20px;
		margin-bottom: 4px;
		// min-height: 409px;
		.policy-right {
			width: 100%;
			margin-top: 16px;

			.policy-right-item {
				cursor: pointer;
				width: 170px;
				margin-right: 10px;
				position: relative;
				transition: all 0.5s;

				&:hover {
					transform: translateY(-10px);
					color: #e50114;

					p {
						color: #e50114;
					}
				}

				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 20px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				img {
					width: 100%;
					object-fit: cover;
					height: 110px;
					border-radius: 5px;
				}

				.mask {
					width: 100%;
					height: 110px;
					background: rgba(0, 0, 0, 0.39);
					border-radius: 5px;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;

					img {
						width: 22px;
						height: 22px;
					}
				}

				span {
					height: 22px;
					line-height: 22px;
					font-size: 14px;
					margin-top: 8px;
				}

				p {
					height: 20px;
					font-size: 13px;
					line-height: 25px;
					color: #999999;
				}
			}
		}
	}
}

.athletes {
	margin: 20px auto 0px auto;

	align-items: flex-start;

	.athletes-box {
		width: 670px;
		background: #fff;
		padding: 20px 0px 0 0px;
		border-radius: 3px;

		.title {
			width: 625px;
			margin: 0 22px;
		}
	}

	.athletes-per {
		width: 100%;
		height: 370px;
		display: flex;
		// border: 1px solid #000;
		// padding: 0 25px;
		// justify-content: center;
		margin-left: 20px;
		// min-height: 520px;
		.athletes-per-item {
			margin-top: 20px;
			width: 196px;
			margin-right: 25px;
			cursor: pointer;
			transition: all 0.5s;

			&:hover {
				transform: translateY(-10px);
			}

			img {
				width: 100%;
				height: 270px;
				border-radius: 3px;
				object-fit: cover;
			}

			p {
				color: #777777;
				font-size: 14px;
				margin-top: 5px;
				line-height: 25px;
				max-height: 45px;
				text-align: center;
			}

			.three-line2 {
				color: #777777;
				font-size: 14px;
				margin-top: 5px;
				line-height: 25px;
				height: 50px;
				word-break: break-all;
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			span {
				width: 100% !important;
				line-height: 25px;
				color: #333333;
				height: 25px;
				font-size: 16px;
				text-align: center;
				margin-top: 14px;
			}
		}
	}
}

.sponsor {
	background: #fff;
	padding: 55px 0px 62px 0px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;

	.left {
		height: 33px;
		font-size: 24px;
		font-weight: bold;
		line-height: 33px;
		width: 141px;
		margin-left: 80px;
	}

	.sponsor-title-one {
		height: 56px;
		font-size: 28px;
		font-weight: bold;
		line-height: 56px;
		color: #e50114;
		padding-bottom: 9px;
		border-bottom: 1px solid #e50114;
	}

	.sponsor-title-two {
		height: 42px;
		font-size: 20px;
		// font-weight: bold;
		line-height: 42px;
		color: #d6ad6e;
		margin-top: 22px;
		text-align: center;
	}

	.sponsor-main {
		width: 1200px;
		height: 170px;
		// background: rgba(244, 246, 249, 0.39);
		border-radius: 3px;
		margin-top: 34px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.link-wrap {
		width: auto !important;
		padding: 0 48px !important;
	}

	.sponsor-official {
		flex: 1;
		// justify-content: center;
		// margin-top: 22px;
		.sponsor-item {
			cursor: pointer;
			width: 286px;
			height: 122px;
			border: 1px solid #dddddd;
			border-radius: 3px;
			margin-right: 18px;
			margin-bottom: 20px;
			margin-top: 22px;

			img {
				width: 100%;
				height: 100%;

				object-fit: cover;
			}
		}

		.link {
			width: 153px;
			align-items: center;
			justify-content: center;
			text-align: center;
			// margin-right: 96px;
			margin-bottom: 20px;
			cursor: pointer;
			text-align: center;

			&:nth-child(6n) {
				margin-right: 0;
			}

			img {
				width: 150px;
				height: 52px;
				object-fit: cover;
				border-radius: 50%;
			}

			span {
				margin-top: 5px;
				width: 153px;
				height: 20px;
				font-size: 14px;
			}
		}
	}
}
</style>
